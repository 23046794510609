
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css'
import Home from './component/Home';
import Header from './component/header/header';
import Footer from './component/footer/footer';
import PageNotFound from './component/PageNotFound/PageNotFound'
import StaticPage from './component/staticpage/StaticPage';
function App() {
  return (
    <> <BrowserRouter>
      <Header></Header>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<StaticPage title="about" />} />
        <Route path="/contact" element={<StaticPage title="contact" />} />
        <Route path="/privacy" element={<StaticPage title="privacy" />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer></Footer>
    </BrowserRouter>
    </>
  );
}

export default App;
