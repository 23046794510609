import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tasks: [{id:1,text:'abhishek kashyap'}],
};

const todoSlice = createSlice({
  name: "todoswhatevername",
  initialState,
  reducers: {
    addTodo: (state, action) => {
      state.tasks.push({ id: Date.now(), text: action.payload });
    },
    deleteTodo: (state, action) => {
      state.tasks = state.tasks.filter((task) => task.id !== action.payload);
    },
  },
});
//console.log(todoSlice)
const {actions, reducer}=todoSlice
export const { addTodo, deleteTodo } = actions;

export default reducer;