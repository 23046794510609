
import React from 'react';
import { useSelector } from 'react-redux';
//import { addTodo, deleteTodo } from '../features/todo/todoSlice';

function HeaderInfo({title,cls='text-left',from=''}) {
    // useSelector is used for read the state
    let video_count_str='';
    const videos_count = useSelector((state) => state.video.videoData.length); 
    if(from=='video'){
         video_count_str=`(${videos_count})`;
    }
    
    //  this todo (state.todo.tasks) coming from store.js , here is snippet
    //  const store = configureStore({
    //     reducer: {
    //       todo:todoReducer,
    //     },
    //   });
    //const dispatch = useDispatch();
    //here dispatch is used for function call, there are two functions imported { addTodo, deleteTodo } see below lines
    //<p onClick={()=>dispatch(deleteTodo(1))}>Click to delete </p>
    //console.log(videos.length)
   
    return (
        <div className={`header-info ${cls}`}>
            <h3>
              {title} 
              {video_count_str}
            </h3>
            {/* <br></br>
            <p onClick={()=>dispatch(deleteTodo(1))}>Click to delete </p>
            <p onClick={()=>dispatch(addTodo('ayush'))}>Click to add </p> */}
            
        </div>
    )
}

export default HeaderInfo