import "./footer.css";

function Footer() {
  return (
    <div className="mt-100">
      <div className="position-fixed bottom-0 bg-dark w-100 text-white p-3 footer">Copyright 2024 © All Rights Reserved by EXPkernel</div>
    </div>
  );
}

export default Footer;