import Carousel from 'react-bootstrap/Carousel';
import { useEffect, useState } from 'react';

function HomeBanner() {
  const [bannerData, setData] = useState([])
  const [isLoading, setLoading] = useState(true)
  const banner_images = async () => {
    const res = await fetch("https://expkernel.com/api/top_banners");
    const result = await res.json();
    setData(result)
    setLoading(false)
  }
  useEffect(() => {
    banner_images()
  }, [])
  const bannerItems = bannerData.map((bimg, index) =>
    <Carousel.Item key={index}>
      <img
        className="d-block w-100"
        src={bimg}
        alt=""
      />
    </Carousel.Item>
  );

  return (
    <Carousel data-bs-theme="dark">
      {bannerItems}
    </Carousel>
  );
}

export default HomeBanner;