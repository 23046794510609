import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useEffect } from 'react';
import HeaderInfo from './header-info';
import { useSelector, useDispatch } from 'react-redux';


import { updateList} from './videoSlice';

function VideoCategory() {
  // const [videoData, setData] = useState([])
  // const [isLoading, setLoading] = useState(true)
  const dispatch=useDispatch();
  const videoData = useSelector((state) => state.video.videoData);
  
  const featured_videos = async () => {
    const res = await fetch("https://expkernel.com/api/featured_videos");
    const result = await res.json();
    dispatch(updateList(result));
    //setData(result)
   // setLoading(false)
  }
  useEffect(() => {
    featured_videos()
  }, [])
  
  
  const videoItems = videoData.map((videoitem, index) =>
   
      <div className='card' key={index}>
        <a rel="noreferrer" href={videoitem.socialLink} target='_blank'>
          <img alt='' className='card-img' src={videoitem.image} />
          <div className='watch'>Watch</div>
        </a>
      </div>
  );

  return (
    <>
      <HeaderInfo title="Featured Videos" from="video" />

      <Container >
        <Row>
          {videoItems}
        </Row>
      </Container>
    </>


  );
}

export default VideoCategory;