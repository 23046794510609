import React from 'react'
import HeaderInfo from '../header-info';
import './staticpage.css';
const page = {
  'about': {
    pagetitle: 'About Us',
    pagedesc: '<p>ExpKernel is an innovative online education platform designed to support class students of all ages and academic levels</p><p>With ExpKernel, students can access a wide range of educational resources, including video lectures, interactive quizzes, and downloadable study materials</p><p>ExpKernel features a user-friendly interface that makes it easy for students to navigate and find the information they need</p>'
  },
  'contact': {
    pagetitle: 'Contact Us',
    pagedesc: '<p>For any update, you can follow our social media page or to contact us. You can send suggestions or query to info@expkernel.com Our support team will assist you shortly.</p>'
  },
  'privacy': {
    pagetitle: 'Privacy Policy',
    pagedesc: '<h3>Data Security</h3><p> We take the security of your personal information seriously and take appropriate measures to protect it. We use industry-standard security technologies and procedures to protect your personal information from unauthorized access, use, or disclosure. We use encryption and secure socket layer (SSL) technology to protect the transmission of your personal information to and from our website. We also use firewalls to protect our servers from unauthorized access.</p><h3>Changes to this Privacy Policy.</h3><p> We may update this Privacy Policy from time to time, and we will post any changes on this page. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your personal information.</p><h3>Disclosure of Information</h3><p> We will not disclose your personal information to third parties without your consent, except as required by law or to protect the rights and safety of our users or others. We may share your personal information with third-party service providers who assist us in providing our services, such as hosting providers, analytics providers, and payment processors. These third-party service providers are bound by strict confidentiality agreements and are only allowed to use your personal information as necessary to provide their services to us. We may also disclose your personal information as required by law, such as in response to a subpoena or court order.</p>'
  }
}

function StaticPage(props) {
  return (
    <div className=''>
      <HeaderInfo title={page[props.title].pagetitle}></HeaderInfo>
      <div className='main_info' dangerouslySetInnerHTML={{ __html: page[props.title].pagedesc }}>

      </div>
    </div>
  )
}

export default StaticPage