import { configureStore } from "@reduxjs/toolkit";
import todoReducer from "./features/todo/todoSlice";
import videoReducer from "./component/videoSlice"

const store = configureStore({
  reducer: {
    todo:todoReducer,
    video: videoReducer
  },
});

export default store;