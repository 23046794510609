import React from 'react'
import '../App.css';

import HomeBanner from './home-banner';
import HeaderInfo from './header-info';
import VideoCategory from './VideoCategory';
function Home() {
  return (
    <div className="App">
     
      <HeaderInfo title="EXPKERNEL- EdTech Platform"></HeaderInfo>
      <HomeBanner></HomeBanner>
      <VideoCategory></VideoCategory>
     
    </div>
  )
}

export default Home