import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  videoData: [],
};

const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    updateList: (state, action) => {
      state.videoData=(action.payload);
    },
    // deleteTodo: (state, action) => {
    //   state.tasks = state.tasks.filter((task) => task.id !== action.payload);
    // },
  },
});
//console.log(todoSlice)
const {actions, reducer}=videoSlice
export const { updateList } = actions;

export default reducer;

