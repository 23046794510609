import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { Image } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './header.css'

function Header() {
  return (
    <div className=''>
      <Navbar bg="dark" data-bs-theme="dark">
        <Container >
          <Navbar.Brand as={NavLink} to="/"><Image style={{width:60}} src="../logo.png"></Image>
          </Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to="/">Home</Nav.Link>
            <Nav.Link as={NavLink} to="/about">About Us</Nav.Link>
            <Nav.Link as={NavLink} to="/contact">Contact Us</Nav.Link>
            <Nav.Link as={NavLink}to="/privacy">Privacy</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;