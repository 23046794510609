import React from 'react'
import HeaderInfo from '../header-info';
import './pagenotfound.css';
import { Link } from 'react-router-dom';
function PageNotFound() {
  return (
    <div className=''>
        <HeaderInfo title="Page Not found" cls="text-center"></HeaderInfo>
        <div className='main_info'>
        <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
        <Link to="/">Go to Home Page</Link>
        </div>
    </div>
  )
}

export default PageNotFound